import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import styles from "./blog.module.scss";

export default (props) => {

	const {
		data: {
			blogPage,
			blogPage: {
				title,
				ACFBlog: {
					blogInfo: {
						left: {
							leftBlurb,
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading
							}
						},
						blogContent
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			posts: {
				nodes: posts
			},
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
		}
	} = props;


	return (
		<Layout>
			<Seo post={blogPage} />
			<div className={`${styles.top} hero`}>
				{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
				{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styles.bgmobile} loading={"eager"} /> }
				{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }
				{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
				{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }
	
				<div className={styles.left}>

					<h2 className={styles.servicename} dangerouslySetInnerHTML={{__html: title}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2>
					{ introHeading && <div className={styles.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in"></div> }
				</div>

				<div className={styles.right}>
					<div className={styles.lefttop}>
						<h1 className={styles.blogtitle} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styles.leftbottom}>
						{ leftBlurb && <div className={styles.leftblurb} dangerouslySetInnerHTML={{__html: leftBlurb}}></div> }
					</div> 
				</div>
			</div>
			<div className={styles.bottom}>
				{ blogContent && <div className="wpcontent" dangerouslySetInnerHTML={{__html: blogContent}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
				{ posts && posts.length > 0 && (
					<div className={styles.postcontainer}>
						{  posts && posts.map((post, index) => {
							return (
								<Link key={post.id} to={post.link} className={styles.post} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
									<span className={styles.title} dangerouslySetInnerHTML={{__html: post.title}}></span>
									<span className={styles.date} dangerouslySetInnerHTML={{__html: post.date}}></span>
									<div className={styles.excerpt} dangerouslySetInnerHTML={{__html: post.excerpt}}></div>
									<span className={styles.readmore}>Read more <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11">
											<g fill="none" fillRule="evenodd">
												<g fill="#656565" className={styles.iconarrow}>
													<g>
														<g>
															<g>
																<path d="M129.638 11.04l-2.44-2.452c-.336-.339-.882-.339-1.218 0-.337.339-.337.887 0 1.226l3.91 3.932c.04.04.085.076.132.108.02.013.04.022.06.033.03.016.058.034.089.047.025.01.05.016.077.024.028.009.055.02.084.025.044.009.089.012.133.013.012 0 .023.004.035.004.013 0 .026-.004.039-.004.043-.002.086-.004.129-.013.03-.006.059-.017.088-.027.025-.007.05-.013.073-.022.033-.014.064-.033.095-.05.018-.01.036-.018.053-.03.048-.032.093-.068.133-.109l3.91-3.931c.169-.17.253-.391.253-.613 0-.222-.084-.444-.253-.613-.336-.339-.882-.339-1.219 0l-2.439 2.453V5.867c0-.479-.386-.867-.862-.867s-.862.388-.862.867v5.174z" transform="translate(-266 -2176) translate(140 1911) translate(0 93) translate(0 168) rotate(-90 130.5 9.5)"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</svg>
									</span>
								</Link>
							)
						}) }
					</div>
				)}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		blogPage: wpPage(id: { eq: $id }) {
			id
			title
			ACFBlog {
				blogInfo {
					left {
						leftBlurb
						requestAppointmentLink {
							title
							url
							target
						}
					}
					right {
						introContent {
							blurb
							heading
						}
		
					}
					blogContent
				}
			}
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		appointmentPage: allWpPage(filter: {template: {templateName: {eq: "Appointments Page"}}}) {
			nodes {
			  link
			}
		}
		introBGMobile: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 699) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGTablet: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 550) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGDesktop: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 840) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGXL: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		posts: allWpPost (filter: {status: {eq: "publish"}}, sort: {fields: date, order: DESC}) {
			nodes {
				id
				title
				date(formatString: "MMMM D, Y")
				excerpt
				link
				ACFPostInfo {
					postInfo {
						right {
							postContent
						}
					}
				}
			}
		}
	}
`;